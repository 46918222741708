import React from "react";
import '/src/assets/css/Common/left-text-content-block.css'


const LeftTextContentBlock = ({title, text1, text2, text3, text4, text5, oList, uList}) => {
    return (
        <section>
            <section id="left-text-content-block">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {title ? <h2 className="mb-5 h3-title font-weight-bolder">{title}</h2> : ''}
                            {text1 ? <p>{text1}</p> : ''}
                            {text2 ? <p>{text2}</p> : ''}
                            {text3 ? <p>{text3}</p> : ''}
                            {text4 ? <p>{text4}</p> : ''}
                            {text5 ? <p>{text5}</p> : ''}
                            {uList ? <ul>
                                {uList.map(el =>
                                    (<li>{el}</li>)
                                )}
                            </ul> : ''}
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default LeftTextContentBlock