import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import HostingPlanBlock from "../components/HostingPlans/HostingPlanBlock"
import InfoBlocks from "../components/Index/InfoBlocks"
import ClientFeedback from "../components/Index/ClientFeedback"
import Footer from "../components/App/Footer"
import BannerArea from "../components/Common/BannerArea"
import SEO from "../components/App/SEO"
import IntroHostingPlansParams from "../components/HostingPlans/IntroHostingPlansParams"
import PromoInfoRowParams from "../components/PromoInfoRow/PromoInfoRowParams"
import SiteBenefitsParams from "../components/SiteBenefits/SiteBenefitsParams"
import CustomerSupportParams from "../components/Index/CustomerSupportParams"
import kvmVirtualizor from "/src/assets/images/kvm-virtualizor.png"
import LeftTextContentBlock from "../components/Common/LeftTextContentBlock";
import VpsFaqs from "../components/VpsServers/VpsFaqs";
import IntroHostingBgWhite from "../components/HostingPlans/IntroHostingBgWhite";

const ServereVps = () => {
    return (
        <Layout>
            <SEO title="Beneficiază de un server VPS România performant - SiteBunker.ro"
                 description="Bucură-te de resurse dedicate KVM pentru orice server VPS România achiziționat de la Sitebunker.ro. Contactează-ne pentru detalii și află ce beneficii ai."
            />
            <Navbar/>
            <IntroHostingBgWhite
                title={'Servere VPS România cu virtualizare KVM și stocare NVMe'}
                subtitle={'Poți alege dintre 5 pachete de Servere VPS cu resurse dedicate și trafic nelimitat sau îți putem oferi un pachet personalizat pentru nevoile tale.\n'}
            />

            <div className="vps-servers">

                <HostingPlanBlock category="VPS"/>

                <BannerArea
                    pageTitle="Servere VPS România"
                    pageSubtitle="La SiteBunker ai propriul Server VPS România propulsat de procesoarele AMD EPYC cu stocare full-SSD NVMe în RAID 10."
                    pageDescription="Servere dual socket, procesoare AMD Epyc, memorii RAM de 1TB, optimizate la cea mai mare frecvență stabilă sunt acum la îndemână la costuri predictibile. Contactează echipa suport şi cere detalii."
                    svgIllustration="server-vps"
                    logo={kvmVirtualizor}
                />
            </div>
            <PromoInfoRowParams
                title1="Server VPS România ultra performant"
                title2="Securitate şi protecție avansată"
                paragraph1="Serverele VPS oferite de SiteBunker sunt optimizate pentru performanță şi asigură echilibru ideal între spațiul de stocare, CPU şi RAM alocat. Găzduim în siguranță aplicații web, site-uri de prezentare, bloguri, diferite servere de mail-uri sau jocuri."
                paragraph2="Alături de monitorizarea eficientă 24/7 şi optimizarea la cea mai mare frecvență stabilă, orice server VPS România SiteBunker este în permanență la adăpost prin noua protecția avansată implementată impotriva atacurilor de tip DDoS."
            />
            <div className="remove-top-padding">
                <InfoBlocks/>
            </div>

            <LeftTextContentBlock title='Ce înseamnă găzduirea pe servere premium VPS România?'
                                  text1='Găzduirea pe servere VPS România este un serviciu mult mai performant, mai eficient și în general este destinat celor care folosesc website-uri ce au nevoie de mai multe resurse, de un mediu digital cât de sigurse poate și de opțiunea de a customiza pachetul și resursele acestuia.'
                                  text2='VPS se traduce prin Virtual Private Server și înseamnă că practic, website-ul tău nu va împărți resursele serverului cu nimeni altcineva. În schimb, are propriile resurse alocate și funcționează în regim privat. Serverul VPS România este foarte similar cu un server dedicat, doar că în acest context, serverul este împărțit digital în mai multe partiții. Datorită acestui regim de funcționare și a inovației acestor servere, clienții primesc acces la un nou set de beneficii.'/>


            <LeftTextContentBlock title='Beneficiile unui Server VPS Premium'
                                  uList={['Performanțe mult mai bune pe care le resimte utilizatorul website-ului.', 'Poți oricând migra de pe pachetul de găzduire web către un server VPS România.', 'Website-ul răspunde mult mai bine, se încarcă mai repede și poate susține o activitate de business mai intensă.', 'Nu împarți resursele (spațiu de stocare, procesor, RAM, viteză de internet) cu nimeni altcineva.']}/>


            <LeftTextContentBlock title='Performanțele unui server VPS România'
                                  text1='Cel mai bun lucru pe care îl poți face pentru serverul tău virtual este să alegi un server cu SSD-uri NVMe. În comparație cu soluțiile de stocare clasice pe SATA, viteza de transfer de date este mult mai mare, iar când adaugi un astfel de feature la un VPS, performanțele vor fi mult mai mari. Iar toate pachetele de găzduire și VPS-uri de la Sitebunker includ SSD-uri NVMe.'
                                  text2='Când performanțele unui server virtual privat (VPS ) sunt activate pentru un website, acest lucru este resimțit direct în utilizarea platformei. Utilizatorii sunt primii care experimentează timpi de încărcare mult mai performanți. Transferul de date se face mult mai repede, comunicarea este mult mai eficientă, iar experiența de utilizator este mult mai bună în ansamblu.'
                                  text3='Datorită acestei performanțe crescute, website-ul sau aplicația ta poate să ruleze mai multe scripturi, mai multe features și să fie mult mai atractiv, dar și productiv.'/>


            <LeftTextContentBlock title='Pachetul de găzduire prin VPS poate fi customizat'
                                  text1='Atunci când găzduirea este excelentă și te ajută să îți dezvolți tot mai mult afacerea, vei ajunge în momentul în care trebuie să depășești 1 anumit prag de performanță. Poate că fluxul de clienți este atât de mare încât resursele alocate pe serverul tău privat(VPS România), nu mai sunt suficiente pentru a menține același nivel de viteză. '
                                  text2='Din fericire, poți oricând să suplimentezi serverul tau virtual cu resursele de care ai nevoie.'
                                  uList={['Intră în apel cu noi sau scrie-ne pe email sau într-un ticket', 'Vorbește-ne despre nevoile tale și despre situația website-ului tău.', 'Noi îți vom recomanda ce anume ar trebui să adăugăm pachetului tău.', 'Poți suplimenta: Puterea de procesare / RAM / Spațiul de Stocare.']}/>


            <LeftTextContentBlock
                title='VPS Este un server dedicat virtual doar pentru tine prin intermediul virtualizării KVM'
                text1='Serverul VPS este o soluție modernă, care nu îți creează neplăceri, care nu îți dă niciodată impresia că este insuficientă și care poate fi folosită pentru mai multe categorii de platforme din mediul online. Aici sunt incluse website-urile de prezentare, website-urile de vânzare a serviciilor, magazinele online, aplicațiile web, servere de jocuri, etc. Iar pentru că ai posibilitatea de a customiza pachetul, nu ești obligat să plătești pentru foarte multe resurse de la început.'
                text2='Iar tot acest context performant, împreună cu faptul că resursele alocate sunt folosite numai de către website-ul tău și nu sunt împărțite, așa cum sunt în sistem de shared hosting, va face ca website-ul tău să se comporte impecabil, să fie mai sigur și să răspundă extrem de bine la update-uri și solicitări mari.'/>


            <LeftTextContentBlock title='Ce înseamnă găzduirea pe servere premium VPS România?'
                                  text1='Găzduirea pe servere VPS România este un serviciu mult mai performant, mai eficient și în general este destinat celor care folosesc website-uri ce au nevoie de mai multe resurse, de un mediu digital cât de sigurse poate și de opțiunea de a customiza pachetul și resursele acestuia.'
                                  text2='VPS se traduce prin Virtual Private Server și înseamnă că practic, website-ul tău nu va împărți resursele serverului cu nimeni altcineva. În schimb, are propriile resurse alocate și funcționează în regim privat. Serverul VPS România este foarte similar cu un server dedicat, doar că în acest context, serverul este împărțit digital în mai multe partiții. Datorită acestui regim de funcționare și a inovației acestor servere, clienții primesc acces la un nou set de beneficii.'/>


            <VpsFaqs/>

            <LeftTextContentBlock title='Asistență Tehnică 24/7'
                                  text1='Atât pentru pachetele noastre de găzduire prin VPS, cât și pentru orice alt serviciu pe care alegi să îl cumperi de la noi, vei beneficia de un serciviu complet de suport și asistență tehnică.'
                                  text2='Suntem cunoscuți în special pentru interesul crescut pe care îl acordăm clienților noștri și pentru că oferim ajutor de specialitate, dar și tehnic, pentru toate problemele pe care le întâmpină clienții noștri.'
                                  text3='Indiferent de natura problemei, dacă o afacere nu poate fi productivă pentru că website-ul are probleme, noi suntem acolo să oferim ajutor.'/>


            <ClientFeedback
                title={"Află recenziile clienților"}
                bold={" SiteBunker!"}/>
            <SiteBenefitsParams
                mainTitle={"Server VPS România CU RESURSE PREMIUM INCLUSE"}
                title1="BACKUP ZILNIC OPTIONAL"
                title2="SECURITATE PERFORMANTĂ"
                title3="SUPORT 24/7"
                text1="Pentru orice server VPS România achiziționat de la SiteBunker poti opta pentru backup automat contra-cost, realizat într-o locație externă."
                text2="Beneficiezi de securitate sporită a datelor şi virtualizare, alături de virtualizarea de la  KVM pentru alocarea resurselor."
                text3="Ai asigurată asistența tehnică 24/7. Intră în legătură cu echipa suport prin sistemul de ticketing şi îți oferim soluții pentru orice provocare tehnică."/>
            <CustomerSupportParams
                title="Descoperă beneficiile unui hosting VPS oferit de SiteBunker"
                paragraph1="Acum ai posibilitatea să te bucuri de beneficiile parteneriatelor SiteBunker."
                paragraph2=" Contactează echipa de suport şi comandă un hosting VPS. Află inclusiv detalii despre reseller hosting"
                secondaryLink="aici"
                buttonText="Cere detalii aici"
            />
            <Footer/>
        </Layout>
    )
}

export default ServereVps
