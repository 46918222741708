import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel
} from "react-accessible-accordion";

const VpsFaqs = () => {
    return (
        <section id="ssl-faqs">
            <div className="container">
                <h2 className="h3-title main-color font-weight-bolder mb-4">Întrebări Frecvente</h2>
                <div className="faq-accordion">
                    <Accordion>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Cât de frecvent este efectuat backup-ul optional si contra-cost al datelor?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                    Backup-ul datelor va fi efectuat în fiecare seară, cu o retenție de 2 zile.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Care este sistemul de operare al serverului meu VPS România?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>În momentul în care efectuezi achiziția serviciului de VPS, vei avea posibilitatea să
                                    alegi orice sistem de operare dorești. Acest lucru se datorează virtualizării KVM și
                                    fiecare server poate avea orice sistem de operare. Totul depinde de preferințele
                                    clientului.</p>
                            </AccordionItemPanel>
                        </AccordionItem>

                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Ce model de procesoare are pachetul meu de găzduire?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>Fiecare Server VPS poate avea 1, 2, 3, 4 sau chiar 6 nuclee din cele 2 procesoare AMD
                                    Epyc 7502 cu 128 de nuclee pentru fiecare procesor. Numărul de nuclee poate varia în
                                    funcție de pachetul de găzduire prin VPS ales. Dacă ai nevoie de mai multe nuclee,
                                    ne poți contacta, iar noi vom reveni către tine cu o ofertă personalizată.</p>
                            </AccordionItemPanel>
                        </AccordionItem>

                    </Accordion>
                </div>
            </div>
        </section>
    )
}


export default VpsFaqs
